import React from "react"

const Figures = () => {
  return (
    <section className="figures-container">
      <div className="figure-container">
        <h1>$1B+</h1>
        <h3>Total Asset Value</h3>
      </div>
      <div className="figure-container">
        <h1>9</h1>
        <h3>No. of Properties</h3>
      </div>
      <div className="figure-container">
        <h1>195,200</h1>
        <h3>Square Feet in Portfolio</h3>
      </div>
    </section>
  )
}

export default Figures
