/* eslint-disable */
import React, { useState } from "react"
import { Link } from "gatsby"

import { useLogos } from "../../../hooks/useLogos.component"
import PartnersLogos from "../../partnersLogos/PartnersLogos.component"

const LogosTable = () => {
  const {
    consultantsAndFinancialPartners,
    brands,
    artistsAndDesigners,
  } = useLogos()

  const [activeLogos, setActiveLogos] = useState(
    consultantsAndFinancialPartners
  )

  return (
    <section className="logos-table">
      <div>
        <ul>
          <li
            className={
              activeLogos === consultantsAndFinancialPartners ? "active" : null
            }
            onClick={() => setActiveLogos(consultantsAndFinancialPartners)}
          >
            Consultants & Financial Partners
          </li>
          <li
            className={activeLogos === brands ? "active" : null}
            onClick={() => setActiveLogos(brands)}
          >
            Brands
          </li>
          <li
            className={activeLogos === artistsAndDesigners ? "active" : null}
            onClick={() => setActiveLogos(artistsAndDesigners)}
          >
            Artists & Designers
          </li>
        </ul>
      </div>
      <PartnersLogos logos={activeLogos} max={15} />
      <Link to="/partners">
        <button>
          <span>View all</span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 404.375 404.375">
            <path
              d="M108.669 404.375c-4.18 0-7.837-1.567-10.971-4.702-6.269-6.269-6.269-16.196 0-21.943l176.065-175.543L97.698 26.645c-6.269-6.269-6.269-16.196 0-21.943 6.269-6.269 16.196-6.269 21.943 0l187.037 186.514c3.135 3.135 4.702 6.792 4.702 10.971 0 4.18-1.567 8.359-4.702 10.971L119.641 399.673c-3.135 3.135-6.792 4.702-10.972 4.702z"
              fill="#d9cbb9"
            />
          </svg>
        </button>
      </Link>
    </section>
  )
}

export default LogosTable
