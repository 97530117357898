import React, { createContext, useContext, useState } from "react"

const INITIAL_VIDEO_STATE = {
  playVideo: null,
  setPlayVideo: null,
}

export const VideoContext = createContext(INITIAL_VIDEO_STATE)
export const useVideoContext = () => useContext(VideoContext)

export const VideoProvider = ({ children }) => {
  const [playVideo, setPlayVideo] = useState(false)

  const videoUtils = {
    playVideo,
    setPlayVideo,
  }

  return (
    <VideoContext.Provider value={videoUtils}>{children}</VideoContext.Provider>
  )
}
