import React, { useRef, useEffect, useState } from "react"

import { useVideoContext } from "./VideoProvider.component"

import SHORT_VIDEO_LINK from "../../../video/Dobacor.mp4"
const VideoPlaceholder = () => {
  const { playVideo } = useVideoContext()

  const videoRef = useRef(null)

  const [videoLoaded, setVideoLoaded] = useState(true)

  useEffect(() => {
    playVideo ? videoRef.current.pause() : videoRef.current.play()
  }, [playVideo])

  return (
    <div className="video-preview" style={{ opacity: videoLoaded ? 1 : 0 }}>
      <video
        ref={videoRef}
        src={SHORT_VIDEO_LINK}
        preload="auto"
        muted
        playsInline
        autoPlay
        loop
        onError={() => setVideoLoaded(false)}
      />
    </div>
  )
}

export default VideoPlaceholder
