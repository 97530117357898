import React from "react"

import GatsbyImage from "../gatsbyImage/GatsbyImage.component"

const PartnersLogos = ({ logos, min = 0, max = logos.length }) => {
  return (
    <section className="partners-logos">
      {logos.slice(min, max).map((logo, index) => (
        <div key={index} className="partners-logo-container">
          <GatsbyImage
            loadingColor="none"
            image={logo.node.childImageSharp.fluid}
          />
        </div>
      ))}
    </section>
  )
}

export default PartnersLogos
