import React from "react"

import SEO from "../components/seo"
import Video from "../components/homepage/video/Video.component"
import Figures from "../components/homepage/figures/Figures.component"
import Quote from "../components/homepage/quote/Quote.component"
import ProjectsContainer from "../components/projectsContainer/ProjectsContainer.component"
import LogosTable from "../components/homepage/logosTable/LogosTable.component"
import PageHeadline from "../components/pageHeadline/PageHeadline.component"
import Form from "../components/form/Form.component"

const App = () => (
  <>
    <SEO />
    <Video />
    <Figures />
    <Quote />
    <ProjectsContainer max={4} />
    <LogosTable />
    <PageHeadline headline1="Contact" headline2="Us" />
    <Form />
  </>
)

export default App
