import React from "react"

const Quote = () => {
  return (
    <section className="quote">
      <p>Establishing new benchmarks for ultra-luxury spaces.</p>
    </section>
  )
}

export default Quote
