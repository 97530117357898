import React from "react"

import { VideoProvider } from "./VideoProvider.component"
// import PlayButton from "./PlayButton.component"
import VideoPreview from "./VideoPreview.component"
// import VideoModal from "./VideoModal.component"

const Video = () => {
  return (
    <VideoProvider>
      <section>
        {/* <PlayButton /> */}
        <VideoPreview />
        {/* <VideoModal /> */}
      </section>
    </VideoProvider>
  )
}

export default Video
