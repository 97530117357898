import { useStaticQuery, graphql } from "gatsby"

export const useLogos = () => {
  const data = useStaticQuery(graphql`
    {
      consultantsAndFinancialPartners: allFile(
        filter: {
          sourceInstanceName: { eq: "partners" }
          relativeDirectory: { eq: "consultants-and-financial-partners" }
        }
        sort: { fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      brands: allFile(
        filter: {
          sourceInstanceName: { eq: "partners" }
          relativeDirectory: { eq: "brands" }
        }
        sort: { fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      artistsAndDesigners: allFile(
        filter: {
          sourceInstanceName: { eq: "partners" }
          relativeDirectory: { eq: "artists-and-designers" }
        }
        sort: { fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const consultantsAndFinancialPartners =
    data.consultantsAndFinancialPartners.edges
  const brands = data.brands.edges
  const artistsAndDesigners = data.artistsAndDesigners.edges

  return { consultantsAndFinancialPartners, brands, artistsAndDesigners }
}
